import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import {MetaSwiper, SafariSwiper} from "./meta-settings.js";

$(document).ready(function ($) {
  // aos
  setTimeout(() => {
    aosInit();
  }, 0);

  function aosInit() {
    AOS.init({
      startEvent: "DOMContentLoaded",
      initClassName: "aos-init",
      animatedClassName: "aos-animate",
      useClassNames: false,
      disableMutationObserver: false,
      debounceDelay: 50,
      throttleDelay: 99,
      offset: 100,
      delay: 0,
      duration: 700,
      easing: "ease",
      once: true,
      mirror: false,
    });
    AOS.refresh(true);
  }

  // Default Variables
  const burger = document.getElementsByClassName(`menu-btn`)[0];
  const mobileHeader = document.getElementsByClassName(`mobile-header`)[0];
  const header = document.getElementsByClassName(`header`)[0];
  const headerCloseWrapper =
    document.getElementsByClassName(`header-close-wrapper`)[0];

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty(
        "--header-height",
        `${
            document.getElementsByClassName("header")[0].getBoundingClientRect()
                .height
        }px`
    );
  });

  // Show fixed header when scroll to top
  let prevScrollPos = window.pageYOffset;

  window.onscroll = () => {
    const currentScrollPos = window.pageYOffset;
    const headerHeight = header.scrollHeight;

    if (window.scrollY > 0) {
      if (prevScrollPos > currentScrollPos) {
        header.style.top = 0;
        header.classList.add("fixed", "slideDown");
      } else {
        header.style.top = `-${headerHeight + 3}px`;
      }
    }

    if (window.scrollY === 0) {
      header.classList.remove("fixed", "slideDown");
    }

    prevScrollPos = currentScrollPos;
  };

  if (window.scrollY !== 0) {
    header.classList.add("fixed", "slideDown");
  }

  // add FillGap for fixed header, so it will not jump when menu is opened
  if (header.classList.contains("fixed")) {
    scrollLock.addFillGapSelector(".header.fixed");
  }

  // Toggle Menu on burger click function
  function toggleMenu() {
    burger.classList.toggle("active");
    mobileHeader.classList.toggle("active");
    headerCloseWrapper.classList.toggle("active");

    // LockScroll when burger open and enable when closed
    scrollLock.getScrollState()
      ? scrollLock.disablePageScroll(mobileHeader)
      : scrollLock.enablePageScroll();

    burger.classList.contains("active")
      ? (burger.innerHTML = "<span>Close x</span>")
      : (burger.innerHTML = "<span>Menu</span>");
  }

  // Burger Menu functionality
  if (burger) {
    burger.addEventListener("click", toggleMenu);
  }
  if (headerCloseWrapper) {
    headerCloseWrapper.addEventListener("click", toggleMenu);
  }

  // Search block in Header
  window.openSearch = function () {
    header.classList.toggle('open-search');
  };

  window.filterJobsList = function () {
    // Declare variables
    let input, filter, ul, li, jobName, txtValue;

    input = document.getElementById('search-input');
    filter = input.value.toUpperCase();
    ul = document.querySelector(".search-result");
    li = ul.getElementsByTagName('li');

    ul.classList.add('open');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      jobName = li[i].querySelectorAll('.job-name')[0];
      txtValue = a.textContent || a.innerText;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = '';
      } else {
        li[i].style.display = 'none';
      }
    }
  };

  window.closeJobsList = function () {
    const ul = document.querySelector('.search-result');

    ul.classList.remove('open');
  };

  window.openJobsList = function () {
    const ul = document.querySelector('.search-result');

    ul.classList.add('open');
  };

  window.toggleJobSection = function (bool) {
    const jobSection = document.querySelector('.jobs-board-section');

		if (jobSection) {
			bool ? jobSection.classList.remove("hide-jobs") : jobSection.classList.add("hide-jobs")
		}
  };

  document.addEventListener('click', function(event) {
    const input = document.getElementById('search-input');
    const ul = document.getElementById('jobs-search-result');

    if(input && ul) {
      if (event.target !== input && !ul.contains(event.target)) {
        closeJobsList();
      }
    }
  });

	window.formatNumberInput = function (input) {
		input.value = input.value.replace(/[^0-9]/g, "");
	}

  // Loaders in buttons
  window.setLoader = function (e) {
    e.classList.add('loader')
  }

  window.removeLoader = function (e) {
    e.classList.remove('loader')
  }

  // Sticky sidebar
  const sidebarSection = document.querySelector(".insight-section");
  if (sidebarSection) {
    const sidebarInner = sidebarSection.querySelector(".sidebar__wrap");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(elem => {
        if (elem.isIntersecting) {
          sidebarInner.style.maxHeight = 100 + '%'
        }
      });

    }, { threshold: 0.01 });

    observer.observe(sidebarSection.nextElementSibling);

    if (window.innerWidth <= 901) {
      observer.unobserve(sidebarSection.nextElementSibling)
    }
  }

  // Beef Up
  if ($(".mobile-header .sublink-wrap")) {
    $(".mobile-header .sublink-wrap").beefup({
      trigger: ".pop-up-link .sublinks-trigger",
      content: ".sublinks",
      openSingle: true,
    });
  }

	window.beefUpElements = []

  /* ---- Filters ---- */
	const initChekboxesChain = (parent, beefUp, bindAllCheckboxes = false, callback) => {
		const parentCheckbox = parent.querySelector("input[type='checkbox']");

		// Set attribute to current filters box
		if (beefUp) {
			beefUp[0].setAttribute("data-beefup", parentCheckbox.value)
		}

		const parentElement = $(parentCheckbox).closest(".filters-parent")[0];

		const currentChildren = Array.from(parentElement.nextElementSibling?.querySelectorAll(":scope > *") || []).map(item => item.querySelector("input[type='checkbox']"));

		const bindCheckboxes = (checkbox) => {
			const similarCheckboxes = Array.from(document.querySelectorAll(`input[value='${checkbox.value}']`)).filter(input => input.name !== "sectors[]" && input.name !== "types[]");

 			if (similarCheckboxes.length > 0) {
				similarCheckboxes.forEach(el => {
          if(checkbox.name !== "sectors[]"&& checkbox.name !== "types[]") {
            el.checked = checkbox.checked;
          }

					// if (checkbox.checked) {
					// 	beefUp.open();
					// } else {
					// 	beefUp.close()
					// }
				})
			}
		}

		parentCheckbox.addEventListener("change", (e) => {
			if (bindAllCheckboxes) {
				bindCheckboxes(parentCheckbox);

				const similarBeefUpElements = window.beefUpElements.filter(beef => {
					return !beef.closest("#filter-sectors")[0] && !beef.closest("#filter-types")[0] && beef[0].dataset.beefup === e.target.value
				});

				if (similarBeefUpElements.length > 0) {
					similarBeefUpElements.forEach(similarBeefUp => {
						if (parentCheckbox.checked) {
							similarBeefUp.open();
						} else {
							similarBeefUp.close()
						}
					})
				}
			}

			if (currentChildren?.length > 0) {
				if (parentCheckbox.checked) {
					beefUp.open();
				} else {
					beefUp.close();

					currentChildren.forEach(checkbox => {
						checkbox.checked = false;
						bindCheckboxes(checkbox)
					});
				}


			}

			if (callback) callback()
		});

		if (currentChildren?.length > 0) {
			currentChildren.forEach((checkbox, i, array) => {
				checkbox.addEventListener("change", (e) => {
					if (checkbox.checked) {
						parentCheckbox.checked = false;

						bindCheckboxes(parentCheckbox)
					};

					if (bindAllCheckboxes) {
						bindCheckboxes(checkbox)
					}

					const isAllDeselected = array.every(item => !item.checked);

					if (!parentCheckbox.checked && isAllDeselected) {
						parentCheckbox.checked = true
					}

					if (callback) callback()
				})
			});
		}
	}

  const jobsFilters = document.querySelectorAll(".jobs-filters .filters-box");
  if (jobsFilters.length) {
    jobsFilters.forEach(filterElement => {
      const parent = filterElement.querySelector(".filters-parent");

			if (parent.nextElementSibling?.classList.contains("filters-children")) {
				const checkboxBeefUp = $(filterElement).beefup({
					content: ".filters-children",
				});

				window.beefUpElements.push(checkboxBeefUp);

				initChekboxesChain(parent, checkboxBeefUp,  true, () => {
					load('jobs/search', 'form:#filter-keywords', 'form:#filter-regions', 'form:#filter-sectors', 'form:#filter-types')
				})
			} else {
				initChekboxesChain(parent, null,  false, () => {
					load('jobs/search', 'form:#filter-keywords', 'form:#filter-regions', 'form:#filter-sectors', 'form:#filter-types')
				})
			}
    })
  }

  /*  Multi Select */
  function customMultipleSelect(element) {
    const mainInput = element.querySelector("input[readonly]");

    const parentBlocks = element.querySelectorAll(".filters-parent");

    const mainFiltersBoxes = element.querySelectorAll(".filters-box");

    mainFiltersBoxes.forEach((box, i) => {
      const checkboxBeefUp = $(box).beefup({
        content: ".filters-children",
      });


        window.beefUpElements.push(checkboxBeefUp);

        if (element.classList.contains("multiple-select--jobs")) {
            initChekboxesChain(parentBlocks[i], checkboxBeefUp, true)
        } else {
            initChekboxesChain(parentBlocks[i], checkboxBeefUp)
        }

    });

    // Dropdown visibility
    let closeFlag;

    mainInput.addEventListener("focus", () => element.classList.add("open"));

    mainInput.addEventListener("focusout", () => {
      if (closeFlag) element.classList.remove("open");
    });

    element.addEventListener('click', () => {
      const layout = document.querySelector('.select-layout')
      layout.classList.add('active');
    })

    document.addEventListener('click', (e) => {
      const layout = document.querySelector('.select-layout')
      const mask = element.querySelector('.multiple-select__mask')
      if (e.target === layout || e.target === mask) {
        element.classList.remove('open');
        layout.classList.remove('active');
      }
    })

    element.addEventListener("mouseenter", () => closeFlag = false);

    element.addEventListener("mouseleave", () => closeFlag = true)
    // Checkboxes
    const checkboxes = element.querySelectorAll("input[type='checkbox']");

    checkboxes.forEach(checkbox => {
      checkbox.addEventListener("change", () => {
        const checkedValues = Array.from(checkboxes).filter(el => el.checked).map(el => el.value);

        const length = checkedValues.length;

        if (length) {
          mainInput.dataset.value = (length <= 3) ? checkedValues.join(", ") : `${length} selected`
        } else {
          mainInput.dataset.value = ""
        }
      })
    })
  }

  /*  Init Multi Select elements */
  const multipleSelectItems = document.querySelectorAll(".multiple-select");
  const layoutElem = document.querySelector('.select-layout')
  if (multipleSelectItems.length) {
    multipleSelectItems.forEach(item => {
      customMultipleSelect(item)
    })
  }

  /* ---- Filters end ---- */

  // Insights filters
  const insightsTabs = document.querySelectorAll(".content-hub-sidebar__content");
  if (insightsTabs.length) {
    insightsTabs.forEach((item, i) => {
      $(item).beefup({
        trigger: ".content-hub-sidebar__title",
        content: ".content-hub-sidebar__list",
        stayOpen: "first",
      });
    })
  }

  // For Players
  const uploadCVBtn = document.getElementById("upload-cv");

  const players = Array.from(document.querySelectorAll(".plyr-video-player"));

  players.forEach((elem) => {
    const player = new Plyr(elem, {
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "pip",
        "fullscreen",
      ],
    });

    player.on("enterfullscreen", () => {
      header.classList.remove("fixed", "slideDown");
      if (uploadCVBtn) {
        uploadCVBtn.style.opacity = "0";
      }
    });

    player.on("exitfullscreen", () => {
      header.classList.add("fixed", "slideDown");
      if (uploadCVBtn) {
        uploadCVBtn.style.opacity = "1";
      }
    });
  });

  // for fix bg zoom on mobile
  if (document.getElementsByClassName("achievements-section")[0]) {
    const benefitsSectionCont = document.getElementsByClassName(
      "achievements-section"
    )[0];

    if (
      (benefitsSectionCont &&
        navigator.platform.match(/(iPad|iPhone|iPod|Android|Silk)/gi)) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0)
    ) {
      benefitsSectionCont.classList.remove("fixed-bg");
    }
  }

  // Input's functionality
  const inputsArr = Array.from(
    document.getElementsByClassName(`input-element`)
  );
  if (inputsArr.length > 0) {
    inputsArr.forEach((elem) => {
      elem.addEventListener("click", function () {
        elem.classList.toggle("focused");
      });
    });
  }

  // Custom Select
  const select2Array = Array.from(
    document.getElementsByClassName("select2-item")
  );

  if (select2Array.length > 0) {
    select2Array.forEach((elem) => {
      $(elem).select2({
        placeholder: elem.getAttribute("data-placeholder"),
        minimumResultsForSearch: Infinity,
      });
    });
  }

  // Select 2 Contact Us Flex-form
  $("#locationSelect").select2({
    width: "100%",
    placeholder: "General enquiry",
    minimumResultsForSearch: -1,
  });

  // Tabs
  if (document.querySelector(".tabs")) {
    const tabs = document.querySelectorAll(".tab-header"), // Tabs list
      tabsHeader = document.querySelector(".tabs-header"), // Parent of tabs list
      tabsContent = document.querySelectorAll(".tab-content"), // Content List
      contentParent = document.querySelector(".tabs-content");

    // Hide items Function
    function hideItems() {
      tabsContent.forEach((item) => {
        item.classList.remove("active");
      });

      tabs.forEach((item) => {
        item.classList.remove("active");
      });
    }

    // Show items Function
    function showItems(i = 0) {
      tabsContent[i].classList.add("active");
      tabs[i].classList.add("active");
      contentParent.style.height = tabsContent[i].scrollHeight + "px";
    }

    hideItems();
    showItems();

    // Event delegation
    tabsHeader.addEventListener("click", (event) => {
      const target = event.target;

      if (target && target.classList.contains("tab-header")) {
        tabs.forEach((item, i) => {
          if (target == item) {
            hideItems();
            showItems(i);
          }
        });
      }
    });
  }

  // Job search buttons
  const jobSearchInputs = document.querySelectorAll(".job-search-form .keyword-search");
  if (jobSearchInputs.length) {
    jobSearchInputs.forEach(input => {
      input.addEventListener('keydown', (e) => {
        if (e.key === "Enter") {
          load('jobs/search', 'form:#filter-keywords')
        }
      })
    })
  }

  // --------- Sliders --------- //

  // Sectors Swiper
  const sectorSliderSecond = document.querySelector(".sectors-slider");
  if (sectorSliderSecond) {
    const sectorSwiperSecond = new Swiper(".sectors-slider", {
      spaceBetween: 22,
      speed: 300,
      grabCursor: true,
      slidesPerView: 1,
      centeredSlides: true,

      // Autoplay
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      // Navigation
      navigation: {
        nextEl: ".nav-btn-next.sector-first",
        prevEl: ".nav-btn-prev.sector-first",
      },

      pagination: {
        el: ".sectors-pagination-first",
        type: "progressbar",
      },

      // Breakpoints
      breakpoints: {
        1025: {
          centeredSlides: false,
        },
        1920: {
          spaceBetween: 40,
          centeredSlides: true,
          initialSlide: 1,
        },
      },
    });

    SafariSwiper(sectorSwiperSecond);
  }

  // Sectors Swiper
  const sectorSlider = document.querySelector(".sectors-slider-second");
  if (sectorSlider) {
    const sectorSlider = new Swiper(".sectors-slider-second", {
      spaceBetween: 22,
      speed: 300,
      grabCursor: true,
      slidesPerView: 1,
      centeredSlides: true,

      // Autoplay
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      // Navigation
      navigation: {
        nextEl: ".nav-btn-next.sector-second",
        prevEl: ".nav-btn-prev.sector-second",
      },

      pagination: {
        el: ".sectors-pagination-second",
        type: "progressbar",
      },

      // Breakpoints
      breakpoints: {
        1025: {
          centeredSlides: false,
        },
        1920: {
          spaceBetween: 40,
          centeredSlides: true,
          initialSlide: 1,
        },
      },
    });

    SafariSwiper(sectorSlider);
  }

  // Articles Swiper
  const articlesSlider = document.querySelector(
    ".articles-slider.narrow-version"
  );
  if (articlesSlider) {
    const articlesSlides = articlesSlider.querySelectorAll(".swiper-slide");
    const articlesSwiper = new Swiper(".articles-slider.narrow-version", {
      spaceBetween: 24,
      observer: true,
      speed: 600,
      grabCursor: true,
      loop: articlesSlides.length > 1,

      // Navigation
      navigation: {
        nextEl: ".nav-btn-next.article-btn",
        prevEl: ".nav-btn-prev.article-btn",
      },

      // Autoplay
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      // Pagination
      pagination: {
        el: ".articles-pagination",
        type: "progressbar",
      },

      // Breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          centeredSlides: true,
          slidesPerView: "auto",
        },
        // when window width is >= 420px
        420: {
          slidesPerView: "auto",
        },

        // when window width is >= 650px
        650: {
          centeredSlides: false,
          slidesPerView: "auto",
        },
        // when window width is >= 1025px
        1025: {
          slidesPerView: "auto",
          centeredSlides: true,
        },
      },
    });

    articlesSlides.length < 2
        ? articlesSlider.classList.add("overflow-visible")
        : articlesSlider.classList.remove("overflow-visible")

    SafariSwiper(articlesSwiper);
  }

  // Articles Swiper var-2 for Page Builder
  const articlesSliderPB = document.querySelector(
    ".articles-slider.page-builder"
  );
  if (articlesSliderPB) {
    const articlesSlides = articlesSliderPB.querySelectorAll(".swiper-slide");
    const articlesSwiperPB = new Swiper(".articles-slider.page-builder", {
      spaceBetween: 24,
      observer: true,
      speed: 600,
      grabCursor: true,
      loop: articlesSlides.length > 4,

      // Navigation
      navigation: {
        nextEl: ".nav-btn-next.article-pb-btn",
        prevEl: ".nav-btn-prev.article-pb-btn",
      },

      // Pagination
      pagination: {
        el: ".article-pb-pagination",
        type: "progressbar",
      },

      // Autoplay
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      // Breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          centeredSlides: true,
          slidesPerView: "auto",
        },
        // when window width is >= 420px
        420: {
          centeredSlides: true,
          slidesPerView: "auto",
        },
        // when window width is >= 650px
        650: {
          centeredSlides: false,
          slidesPerView: "auto",
        },
        // when window width is >= 1025px
        1025: {
          centeredSlides: false,
          slidesPerView: articlesSlides.length > 4 ? "auto" : 3,
        },
      },
    });

    SafariSwiper(articlesSwiperPB);
  }

  // Clients Swiper
  const clientsSliderFirst = document.querySelector(".clients-slider-1");
  if (clientsSliderFirst) {
    const clientsSlides = clientsSliderFirst.querySelectorAll(".swiper-slide");
    const clientsSwiperFirst = new Swiper(".clients-slider-1", {
      spaceBetween: 16,
      slideToClickedSlide: true,
      slidesPerView: 1,
      loop: clientsSlides.length > 5,

      // Autoplay
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      breakpoints: {
        // when window width is >= 420px
        420: {
          slidesPerView: 2,
        },
      },
    });

    SafariSwiper(clientsSwiperFirst);
  }

  // Clients Swiper
  const clientsSliderSecond = document.querySelector(".clients-slider-2");
  if (clientsSliderSecond) {
    const clientsSlides = clientsSliderSecond.querySelectorAll(".swiper-slide");
    const clientsSwiperSecond = new Swiper(".clients-slider-2", {
      spaceBetween: 16,
      slideToClickedSlide: true,
      slidesPerView: 1,
      loop: clientsSlides.length > 5,

      // Autoplay
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      breakpoints: {
        // when window width is >= 420px
        420: {
          slidesPerView: 2,
        },
      },
    });

    SafariSwiper(clientsSwiperSecond);
  }

  // Testimonials Swiper
  const testimonialsSections = document.querySelectorAll(".testimonials-section");
  if (testimonialsSections.length > 0) {
		testimonialsSections.forEach(section => {
			const testimonialsSlider = section.querySelector(".testimonials-swiper");
			const previewSlider = section.querySelector(".client-preview-slider");
			const pagination = section.querySelector(".swiper-pagination");

			const previewSwiper = new Swiper(previewSlider, {
				slidesPerView: 1,
				allowTouchMove: false,
				effect: "fade",
			});

			const testimonialsSwiper = new Swiper(testimonialsSlider, {
				slidesPerView: 1,

				// Autoplay
				autoplay: {
					delay: 3500,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				},

				thumbs: {
					swiper: previewSwiper,
				},

				autoHeight: true,

				// Pagination
				pagination: {
					el: pagination,
					clickable: true,
					dynamicBullets: true,
					dynamicMainBullets: 3
				},

				breakpoints: {
					551: {
						pagination: {
							dynamicBullets: false
						}
					}
				}
			});

			SafariSwiper(previewSlider);
			SafariSwiper(testimonialsSlider);

			let swiperStatus = false;
			const observer = new IntersectionObserver((entries) => {
				entries.forEach(elem => {
					if (elem.target.classList.contains('testimonials-section')) {
						swiperStatus = elem.isIntersecting
					}
				});

				if (swiperStatus) {
					testimonialsSwiper.autoplay.start();
					testimonialsSwiper.update();
				} else {
					testimonialsSwiper.autoplay.stop();
					testimonialsSwiper.update();
				}
			}, { threshold: 0.5 });

			observer.observe(section);
		})

  }

  // Presentation Swiper
  const presentationSections = document.querySelectorAll(".presentation-section");
  if (presentationSections.length >= 0) {
    	presentationSections.forEach(section => {
					const sliderEl = section.querySelector(".presentation-swiper");
					const sliderImageEl = section.querySelector(".presentation-img-swiper");

					const presentationImageSlider = new Swiper(sliderImageEl, {
						slidesPerView: 1,
						effect: "fade",
						allowTouchMove: false,

						fadeEffect: {
							crossFade: true,
						},
					});

					const presentationSlider = new Swiper(sliderEl, {
						slidesPerView: 1,
						autoHeight: true,

						// Autoplay
						autoplay: {
							delay: 5000,
							disableOnInteraction: false,
							pauseOnMouseEnter: true,
						},

						thumbs: {
							swiper: presentationImageSlider,
						},

						// Pagination
						pagination: {
							el: section.querySelector(".swiper-pagination"),
							clickable: true,
						},
					});

					SafariSwiper(presentationSlider);
					SafariSwiper(presentationImageSlider);
			})
  }

  // Gallery Swiper
  const gallerySectionHorizontal = document.querySelector(
    ".gallery-section.horizontal-gallery"
  );
  if (gallerySectionHorizontal) {
    const gallerySliderHorizontal = new Swiper(".gallery-slider-horizontal", {
      slidesPerView: "2",
      spaceBetween: 0,
      allowTouchMove: false,

      // Navigation
      navigation: {
        nextEl: ".nav-btn-next.gallery-horizontal",
        prevEl: ".nav-btn-prev.gallery-horizontal",
      },

      // Autoplay
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      pagination: {
        el: ".gallery-pagination-horizontal",
        type: "progressbar",
      },

      breakpoints: {
        320: {
          spaceBetween: 16,
          slidesPerView: 2,
          grid: false,
          allowTouchMove: true,
        },

        650: {
          slidesPerView: "auto",
          spaceBetween: 0,
          allowTouchMove: false,
        },
      },
    });

    SafariSwiper(gallerySliderHorizontal);
  }

  const gallerySectionGrid = document.querySelector(
    ".gallery-section.grid-gallery"
  );
  if (gallerySectionGrid) {
    const gallerySliderGrid = new Swiper(".gallery-slider-grid", {
      slidesPerView: 2,
      spaceBetween: 0,
      grid: {
        rows: 2,
      },
      allowTouchMove: false,

      // Navigation
      navigation: {
        nextEl: ".nav-btn-next.gallery-grid",
        prevEl: ".nav-btn-prev.gallery-grid",
      },

      // Autoplay
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      pagination: {
        el: ".gallery-pagination-grid",
        type: "progressbar",
      },

      breakpoints: {
        320: {
          spaceBetween: 16,
          slidesPerView: 2,
          grid: false,
          allowTouchMove: true,
        },

        650: {
          enabled: false,
          slidesPerView: 2,
          spaceBetween: 0,
          grid: {
            rows: 2,
          },
          allowTouchMove: false,
        },
      },
    });

    window.onresize = () =>
      window.innerWidth < 650
        ? (gallerySliderGrid.enabled = true)
        : (gallerySliderGrid.enabled = false);

    SafariSwiper(gallerySliderGrid);
  }

  // Hiring Slider
  const hiringSlider = document.getElementsByClassName("hiring-slider")[0];
  if (hiringSlider) {
    MetaSwiper(".hiring-slider", {
      slidesPerView: 1,
      spaceBetween: 16,
      loop: false,

      navigation: {
        nextEl: ".nav-btn-next.hiring",
        prevEl: ".nav-btn-prev.hiring",
      },

      pagination: {
        el: ".hiring-pagination",
        type: "progressbar",
      },

      // Autoplay
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      breakpoints: {
        650: {
          slidesPerView: 2,
          spaceBetween: 0,
          // allowTouchMove: false,
        },

        961: {
          slidesPerView: 3,
          spaceBetween: 0,
          // allowTouchMove: false,
        },
      },
    });
  }

  // Articles Swiper
  const caseStudiesSlider = document.getElementsByClassName(
    "case-studies-slider"
  )[0];
  if (caseStudiesSlider) {
    MetaSwiper(".case-studies-slider", {
      slidesPerView: 1,
      spaceBetween: 16,
      speed: 600,
      grabCursor: true,
      loop: false,

      navigation: {
        nextEl: ".nav-btn-next.case-studies",
        prevEl: ".nav-btn-prev.case-studies",
      },

      pagination: {
        el: ".case-studies-pagination",
        type: "progressbar",
      },

      // Autoplay
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      breakpoints: {
        375: {
          slidesPerView: 1.5,
          // allowTouchMove: true,
        },

        490: {
          slidesPerView: 2,
          // allowTouchMove: true,
        },

        650: {
          slidesPerView: 2,
          spaceBetween: 16,
          // allowTouchMove: false,
        },

        1026: {
          slidesPerView: 2,
          spaceBetween: 17,
          // allowTouchMove: false,
        },
      },
    });
  }

  // Wheel Slider
  const wheelSection = document.querySelector(".infographic-wheel");
  if (wheelSection) {
    const wheeBox = wheelSection.querySelector(".wheel-sectors");
    // Desktop wheel
    const wheelSlider = new Swiper(".wheel-swiper", {
      effect: "fade",

      autoplay: {
        delay: 1000,
        stopOnLastSlide: true
      },

      navigation: {
        nextEl: ".swiper-btn-next.wheel-btn",
        prevEl: ".swiper-btn-prev.wheel-btn",
      },
    });

    wheelSlider.autoplay.stop()

    // Scroll Magic
    let scrollFlag = true;
    const options = {
      threshold: 1,
      rootMargin: "50% 0% 0% 0%"
    }

    new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (scrollFlag) {
            wheelSlider.slideNext(1000);
            wheelSlider.autoplay.start();
            header.classList.remove("fixed", "slideDown");

            scrollFlag = false
          }
        }
      })
    }, options).observe(wheeBox)

    SafariSwiper(wheelSlider);

    // Wheel settings
    function isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    const circleBlock = wheelSection.querySelector(`.slide-box.level-2`);
    if (circleBlock) {
      const container = circleBlock.querySelector(".icons-container");
      const wrapArr = Array.from(circleBlock.querySelectorAll(`.icon-wrap`));
      const itemArr = Array.from(circleBlock.querySelectorAll(`.icons-list__item`));

      function rotateAnimation() {
        itemArr.forEach((elem) => elem.classList.remove("active"));

        let activeIndex = 0;
        itemArr[0].classList.add("active");

        // setInterval(function () {
          let trueIndex = itemArr.length - (activeIndex % wrapArr.length);

          let previousActiveItem = itemArr[(trueIndex + 1) % itemArr.length];
          let activeItem = itemArr[trueIndex % itemArr.length];

          let activeWrap = wrapArr[trueIndex % wrapArr.length];


          previousActiveItem.classList.remove("active");
          activeItem.classList.add("active");

          wrapArr.forEach((elem, index) => {
            const deg = (activeIndex + index) * step + defaultTransform;
            elem.style.transform = `rotate(${deg}deg)`;

            elem.children[0].style.transform = `rotate(${
                -1 * deg
            }deg) translateY(-50%)`;

            const textItem = elem.querySelector('p');


            // Items on top half of circle
            if (deg >= 120 && deg <= 240) textItem.classList.add('top-full');
            if (deg >= 200 && deg <= 340) textItem.classList.add('right-full', 'text-right');

            // Items on bottom half of circle
            if (deg <= 60 || deg >= 300) textItem.classList.add('bottom-full');
            if (deg >= 20 && deg <= 160) textItem.classList.add('left-full', 'text-left');

            // Items between 60deg and 100deg
            if (deg >= 80 && deg <= 100) textItem.classList.add('pl-2');

            // Items between 240deg and 280deg
            if (deg >= 240 && deg <= 280) textItem.classList.add('pr-2');

            // First item
            if (deg === 0) textItem.classList.add('pb-2');

            // Middle bottom item
            if (deg === 180) textItem.classList.add('pt-2');
          });

          activeIndex += 1;

        // }, 3200);
      }

      itemArr.forEach((elem) => elem.classList.add("active"));

      const step = 360 / wrapArr.length;
      let newStep = step * 2;

      const defaultTransform = +circleBlock
          .getAttribute("data-default-transform")
          .replace("deg", "");

      wrapArr.forEach((elem, index) => {
        elem.style.transform = `rotate(${index * step + defaultTransform}deg)`;
        elem.children[0].style.transform = `rotate(${
            -1 * (index * step + defaultTransform)
        }deg) translateY(-50%)`;
      });

      let scrollFlag = isInViewport(container);

      if (scrollFlag) rotateAnimation();

      document.addEventListener("scroll", function () {
        if (scrollFlag) return;

        scrollFlag = isInViewport(container);
        if (scrollFlag) rotateAnimation();
      });
    }

    // Mobile version
    const mobileTitlesSlider = new Swiper(".titles-swiper", {
      slidesPerView: 1,
      allowTouchMove: false,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    });
    const mobileSlider = new Swiper(".wheel-mob-swiper", {
      slidesPerView: 1,
      autoHeight: true,

      // Autoplay
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      thumbs: {
        swiper: mobileTitlesSlider,
      },

      // Navigation
      navigation: {
        nextEl: ".nav-btn-next.wheel-mob-btn",
        prevEl: ".nav-btn-prev.wheel-mob-btn",
      },

      pagination: {
        el: ".wheel-pagination",
        type: "progressbar",
      },
    });

    // Intersect section to set autoplay
    function iOSversion() {
      if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
      }
    }

    const iOSVersion = iOSversion();
    const safariVersion = String(navigator.sayswho).split(' ');
    let flag = false;

    // return all under Safari 12 Desktop
    if ((safariVersion[0] === 'Safari') && (Number(safariVersion[1]) < 12)) {
      flag = true;
    }

    // return all under Safari 12 iOS
    if (iOSVersion && (iOSVersion[0] < 12 || (iOSVersion[0] === 12 && iOSVersion[1] < 2))) {
      flag = true;
    }

    if (!flag) {
      let swiperStatus = false;
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(elem => {
          if (elem.target.classList.contains('infographic-wheel')) {
            swiperStatus = elem.isIntersecting
          }
        });

        if (swiperStatus) {
          mobileSlider.autoplay.start();
          mobileSlider.update();
        } else {
          mobileSlider.autoplay.stop();
          mobileSlider.update();
        }
      }, { threshold: 0.5 });

      observer.observe(wheelSection);
    }
  }
});
